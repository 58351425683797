// forked from https://nostalgic-css.github.io/NES.css/

$icon-wealthfront-colors: (#fff, #4844bc);
$icon-wealthfront: (
  (0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0),
  (2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2),
  (2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2),
  (2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2),
  (2, 2, 2, 2, 2, 2, 2, 2, 1, 1, 1, 2, 2, 2, 2, 2),
  (2, 2, 2, 2, 2, 2, 2, 2, 1, 1, 1, 2, 2, 2, 2, 2),
  (2, 2, 2, 2, 1, 1, 2, 2, 1, 1, 1, 2, 2, 2, 2, 2),
  (2, 2, 2, 2, 1, 1, 1, 2, 1, 1, 1, 1, 2, 2, 2, 2),
  (2, 2, 2, 2, 1, 1, 1, 2, 1, 1, 1, 1, 2, 2, 2, 2),
  (2, 2, 2, 2, 1, 1, 1, 2, 2, 1, 1, 1, 1, 2, 2, 2),
  (2, 2, 1, 2, 2, 1, 1, 1, 2, 2, 1, 1, 1, 1, 2, 2),
  (2, 2, 1, 1, 2, 2, 1, 1, 1, 2, 2, 1, 1, 1, 1, 2),
  (2, 2, 2, 1, 1, 2, 2, 1, 1, 1, 2, 2, 1, 1, 1, 2),
  (2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2),
  (2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2),
  (0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0)
);

$icon-hopkins-colors: (#fff, #1e376d);
$icon-hopkins: (
  (0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0),
  (2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2),
  (2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2),
  (2, 2, 2, 1, 1, 1, 2, 2, 2, 2, 1, 1, 1, 2, 2, 2),
  (2, 2, 2, 1, 1, 1, 2, 2, 2, 2, 1, 1, 1, 2, 2, 2),
  (2, 2, 2, 1, 1, 1, 2, 2, 2, 2, 1, 1, 1, 2, 2, 2),
  (2, 2, 2, 1, 1, 1, 2, 2, 2, 2, 1, 1, 1, 2, 2, 2),
  (2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2),
  (2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2),
  (2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2),
  (2, 2, 2, 1, 1, 1, 2, 2, 2, 2, 1, 1, 1, 2, 2, 2),
  (2, 2, 2, 1, 1, 1, 2, 2, 2, 2, 1, 1, 1, 2, 2, 2),
  (2, 2, 2, 1, 1, 1, 2, 2, 2, 2, 1, 1, 1, 2, 2, 2),
  (2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2),
  (2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2),
  (0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0)
);

$icon-linkedin-colors: (#fff, #2577b9);
$icon-linkedin: (
  (0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0),
  (2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2),
  (2, 2, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2),
  (2, 2, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2),
  (2, 2, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2),
  (2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2),
  (2, 2, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 2, 2, 2),
  (2, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2),
  (2, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2),
  (2, 2, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 2, 2),
  (2, 2, 1, 1, 1, 2, 1, 1, 1, 2, 2, 1, 1, 1, 2, 2),
  (2, 2, 1, 1, 1, 2, 1, 1, 1, 2, 2, 1, 1, 1, 2, 2),
  (2, 2, 1, 1, 1, 2, 1, 1, 1, 2, 2, 1, 1, 1, 2, 2),
  (2, 2, 1, 1, 1, 2, 1, 1, 1, 2, 2, 1, 1, 1, 2, 2),
  (2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2),
  (0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0)
);

$icon-gmail-colors: (#eeecec, #fd2b2b);
$icon-gmail: (
  (0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0),
  (1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1),
  (0, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 0),
  (2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2),
  (2, 2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2),
  (2, 2, 1, 2, 2, 1, 1, 1, 1, 1, 1, 2, 2, 1, 2, 2),
  (2, 2, 1, 1, 2, 2, 1, 1, 1, 1, 2, 2, 1, 1, 2, 2),
  (2, 2, 1, 1, 1, 2, 2, 1, 1, 2, 2, 1, 1, 1, 2, 2),
  (2, 2, 1, 1, 1, 1, 2, 2, 2, 2, 1, 1, 1, 1, 2, 2),
  (2, 2, 1, 1, 1, 1, 1, 2, 2, 1, 1, 1, 1, 1, 2, 2),
  (2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2),
  (2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2),
  (2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2),
  (0, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 0),
  (1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1),
  (0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0)
);

$icon-github-colors: (#fff, #333);
$icon-github: (
  (0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0),
  (2, 2, 2, 2, 1, 2, 2, 2, 2, 2, 2, 2, 2, 1, 2, 2),
  (2, 2, 2, 2, 1, 1, 2, 2, 2, 2, 2, 2, 1, 1, 2, 2),
  (2, 2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2),
  (2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2),
  (2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2),
  (2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2),
  (2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2),
  (2, 2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2),
  (2, 2, 2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2),
  (2, 1, 1, 2, 2, 2, 2, 1, 1, 1, 1, 2, 2, 2, 2, 2),
  (2, 2, 2, 1, 2, 2, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2),
  (2, 2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2),
  (2, 2, 2, 2, 2, 2, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2),
  (2, 2, 2, 2, 2, 2, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2),
  (0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0)
);

$icon-star-colors: (#fff, #444, #ebe527, #f59f54);
$icon-star: (
  (0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0),
  (0, 0, 0, 0, 0, 0, 2, 3, 2, 0, 0, 0, 0, 0, 0, 0),
  (0, 0, 0, 0, 0, 0, 2, 3, 2, 0, 0, 0, 0, 0, 0, 0),
  (0, 0, 0, 0, 0, 2, 3, 3, 3, 2, 0, 0, 0, 0, 0, 0),
  (0, 0, 0, 0, 0, 2, 1, 1, 3, 2, 0, 0, 0, 0, 0, 0),
  (2, 2, 2, 2, 2, 2, 1, 3, 3, 3, 2, 2, 2, 2, 2, 0),
  (2, 3, 3, 3, 1, 1, 3, 3, 3, 3, 3, 3, 3, 3, 2, 0),
  (0, 2, 3, 3, 1, 3, 3, 3, 3, 3, 3, 3, 4, 2, 0, 0),
  (0, 0, 2, 3, 3, 3, 3, 3, 3, 3, 4, 4, 2, 0, 0, 0),
  (0, 0, 0, 2, 3, 3, 3, 3, 3, 3, 3, 2, 0, 0, 0, 0),
  (0, 0, 2, 3, 3, 3, 3, 4, 3, 3, 3, 3, 2, 0, 0, 0),
  (0, 0, 2, 3, 3, 3, 4, 4, 4, 3, 3, 3, 2, 0, 0, 0),
  (0, 2, 3, 3, 4, 4, 4, 2, 4, 4, 4, 3, 3, 2, 0, 0),
  (0, 2, 3, 4, 4, 2, 2, 0, 2, 2, 4, 4, 3, 2, 0, 0),
  (2, 4, 4, 2, 2, 0, 0, 0, 0, 0, 2, 2, 4, 4, 2, 0),
  (2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 0)
);

@mixin pixelize($size, $matrix, $colors, $default-color: null) {
  $ret: "";
  $moz: "";
  @if ($default-color == null) {
    $matrix-colors: ();
    $counts: ();
    @each $row in $matrix {
      @each $item in $row {
        @if $item != 0 {
          $index: index($matrix-colors, $item);
          @if not $index {
            $matrix-colors: append($matrix-colors, $item);
            $counts: append($counts, 1);
          } @else {
            $count: nth($counts, $index) + 1;
            $counts: set-nth($counts, $index, $count);
          }
        }
      }
    }
    $default-color: nth($colors, nth($matrix-colors, index($counts, max($counts...))));
  }

  @for $i from 1 through length($matrix) {
    $row: nth($matrix, $i);

    @for $j from 1 through length($row) {
      $dot: nth($row, $j);

      @if $dot != 0 {
        @if $ret != "" {
          $ret: $ret + ",";
          $moz: $moz + ",";
        }

        $color: nth($colors, $dot);
        @if $color == $default-color {
          $ret: $ret + ($j * $size) + " " + ($i * $size);
          $moz: $moz + ($j * $size) + " " + ($i * $size) + " 0 0.020em";
        } @else {
          $ret: $ret + ($j * $size) + " " + ($i * $size) + " " + $color;
          $moz: $moz + ($j * $size) + " " + ($i * $size) + " 0 0.020em " + $color;
        }
      }
    }
  }

  width: $size;
  height: $size;
  color: $default-color;
  box-shadow: unquote($ret);

  // firefox only style
  @supports (-moz-appearance: meterbar) {
    box-shadow: unquote($moz);
  }
}

@mixin icon {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  margin-bottom: 16px;

  transform: scale(2);
  transform-origin: top left;

  &::before {
    position: absolute;
    top: -1px;
    left: -1px;
    display: block;
    content: "";
    background: transparent;
  }
}

.github {
  @include icon();
  &::before {
    @include pixelize(1px, $icon-github, $icon-github-colors);
  }
}
.gmail {
  @include icon();
  &::before {
    @include pixelize(1px, $icon-gmail, $icon-gmail-colors);
  }
}
.linkedin {
  @include icon();
  &::before {
    @include pixelize(1px, $icon-linkedin, $icon-linkedin-colors);
  }
}
.wealthfront {
  @include icon();
  &::before {
    @include pixelize(1px, $icon-wealthfront, $icon-wealthfront-colors);
  }
}
.hopkins {
  @include icon();
  &::before {
    @include pixelize(1px, $icon-hopkins, $icon-hopkins-colors);
  }
}
.star {
  @include icon();
  &::before {
    @include pixelize(1px, $icon-star, $icon-star-colors);
  }
}
